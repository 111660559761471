import Rails from '@rails/ujs';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { hexToNumber } from 'web3-utils';
import jazzicon from 'jazzicon';
import ENS from 'ethereum-ens';
import * as basicLightbox from 'basiclightbox';

import { ABI } from './data.json';

// shared instances
let web3;
let web3modal;
let provider;
let contract;

const getWalletAddress = () => {
  if (provider.isMetaMask) return provider.selectedAddress;
  if (provider.isWalletConnect) return provider.accounts[0];
};

const resolveEnsNames = () => {
  // resolve ENS names
  const ens = new ENS(provider);
  const addresses = [...new Set([...document.querySelectorAll('[data-insert-ens-name]')].map((x) => x.dataset.address))];
  addresses.forEach(async (address) => {
    try {
      const name = await ens.reverse(address).name();
      document.querySelectorAll(`[data-insert-ens-name][data-address='${address}']`).forEach((x) => {
        x.innerText = name;
        if (x.dataset.syncTweet) {
          document.querySelector('#tweet_body_input').value = document.querySelector('#tweet_body_input').value.replace(address, name);
        }
      });
    } catch {}
  });
};

const insertJazzicon = (el) => {
  el.appendChild(jazzicon(el.dataset.size || 100, parseInt(el.dataset.address.slice(2, 10), 16)));
};

const logout = () => {
  console.log('clearing session');

  Rails.ajax({
    type: 'post',
    url: '/sessions/logout',
    error: console.error,
    success: (response) => {
      if (!response.ok) return console.warn('error', response);
      window.location.reload();
    },
  });
};

const signNonce = async (nonce) => {
  try {
    return await web3.eth.personal.sign(nonce, getWalletAddress());
  } catch (e) {
    console.warn('[signNonce]', e);
    alert('You must sign the login request.');
  }
};

const init = async () => {
  if (document.querySelector('#wrong-network') && hexToNumber(provider.chainId) !== CONFIG.CHAIN_ID) {
    document.querySelector('#wrong-network').style.display = null;
    return;
  }

  web3 = await new Web3(provider);
  contract = new web3.eth.Contract(ABI, CONFIG.CONTRACT_ADDRESS);

  resolveEnsNames();
  subscribeProviderEvents();
};

const subscribeProviderEvents = () => {
  provider.on('accountsChanged', (accounts) => {
    console.log('[accountsChanged]', accounts);
    logout();
  });

  provider.on('networkChanged', (chainId) => {
    console.log('[networkChanged]', chainId);
    logout();
  });

  provider.on('chainChanged', (chainId) => {
    console.log('[chainChanged]', chainId);
    logout();
  });

  provider.on('connect', (info) => {
    console.log('[connect]', info);
  });

  provider.on('disconnect', (error, reason) => {
    console.log('disconnect', error, reason);
    logout();
  });
};

const login = async () => {
  // request nonce
  const data = new FormData();
  data.append('wallet_address', getWalletAddress());

  Rails.ajax({
    type: 'post',
    url: '/sessions/challenge',
    data,
    error: console.error,
    success: async (response) => {
      if (!response.ok) return console.warn('error', response);
      // console.log(response.nonce);

      // sign nonce
      const signature = await signNonce(response.nonce);
      if (!signature) return console.warn('no signature');
      // console.log({ signature });

      const data = new FormData();
      data.append('wallet_address', getWalletAddress());
      data.append('signature', signature);
      data.append('nonce', response.nonce);

      Rails.ajax({
        type: 'post',
        url: '/sessions/verify',
        data,
        error: console.error,
        success: async (response) => {
          if (!response.ok) return console.warn('error', response);
          console.log(response);
          window.location.reload();
        },
      });
    },
  });
};

const toggleBidLoader = (button, loading) => {
  document.querySelectorAll('.top-bid').forEach((el) => (el.style.display = loading ? 'none' : null));
  document.querySelectorAll('.pending-bid').forEach((el) => (el.style.display = loading ? null : 'none'));

  button.disabled = loading;
  if (loading) button.classList.add('disabled');
  else button.classList.remove('disabled');
};

window.addEventListener('DOMContentLoaded', async () => {
  // prepare modal instance
  web3modal = new Web3Modal({
    network: CONFIG.NETWORK,
    cacheProvider: true,
    providerOptions: {
      injected: {
        display: { name: 'MetaMask', description: 'Connect with the provider in your Browser' },
        package: null,
      },
      walletconnect: {
        display: { name: 'WalletConnect', description: 'Scan QR code with your mobile wallet' },
        package: WalletConnectProvider,
        options: { infuraId: CONFIG.INFURA_PROJECT_ID },
      },
    },
  });

  // reconnect to cached provider
  if (web3modal.cachedProvider) {
    provider = await web3modal.connect();
    await init();
    console.log('reusing cached provider, address', getWalletAddress());
  } else if (document.querySelector('[data-autoconnect-wallet]')) {
    // try to connect wallet if user is already authed
    provider = await web3modal.connect();
    await init();
    console.log('autoconnected wallet to get provider, address', getWalletAddress());
  }

  document.querySelectorAll('[data-wallet-connect]').forEach((button) => {
    button.addEventListener('click', async (e) => {
      e.preventDefault();

      // prompt user to connect wallet if not already cached
      if (!provider) {
        try {
          provider = await web3modal.connect();
          await init();
          console.log('connected to provider, address:', getWalletAddress());
        } catch (e) {
          console.error('web3modal error', e);
          return;
        }
      }

      // asuming we have a provider now, ask user to sign nonce
      await login();
    });
  });

  document.querySelectorAll('[data-wallet-disconnect]').forEach((button) => {
    button.addEventListener('click', async (e) => {
      e.preventDefault();
      await web3modal.clearCachedProvider();
      provider = null;
      logout();
    });
  });

  document.querySelectorAll('[data-burger-toggle]').forEach((button) => {
    button.addEventListener('click', async (e) => {
      e.preventDefault();
      document.querySelector('nav.mobile').classList.toggle('collapsed');
    });
  });

  document.querySelectorAll('[data-hero]').forEach((hero) => {
    const min = parseInt(hero.dataset.min);
    const max = parseInt(hero.dataset.max);
    let current = parseInt(hero.dataset.current);
    let loading = false;

    document.querySelectorAll('[data-hero-arrow]').forEach((arrow) => {
      arrow.addEventListener('click', async (e) => {
        e.preventDefault();
        if (loading) return;

        const offset = parseInt(arrow.dataset.offset);
        const next = current + offset;

        document.querySelector('[data-hero-arrow].left').classList.remove('disabled');
        document.querySelector('[data-hero-arrow].right').classList.remove('disabled');

        if (offset > 0 && current >= max) {
          document.querySelector('[data-hero-arrow].right').classList.add('disabled');
        } else if (offset < 0 && current <= min) {
          document.querySelector('[data-hero-arrow].left').classList.add('disabled');
        } else {
          console.log(`fetching ${next}`);
          loading = true;

          Rails.ajax({
            type: 'get',
            url: `/day/${next}/hero`,
            error: console.error,
            success: async (response) => {
              if (!response.ok) return console.warn('error', response);

              current = next;
              loading = false;

              // replace html
              hero.innerHTML = response.html;

              // retrigger javascript manually
              resolveEnsNames();

              [...document.querySelector('[data-hero]').querySelectorAll('img[data-lazyload]')].forEach((img) => {
                if (img.dataset.lazyload) img.src = img.dataset.lazyload;
              });

              const countdown = document.querySelector('[data-hero]').querySelector('[data-countdown-seconds]');
              if (countdown) {
                render_countdown(countdown);
                setInterval(() => render_countdown(countdown), 500);
              }

              const jazzicon = document.querySelector('[data-hero]').querySelector('[data-insert-jazzicon]');
              if (jazzicon) {
                insertJazzicon(jazzicon);
              }

              if (offset > 0 && current >= max) {
                document.querySelector('[data-hero-arrow].right').classList.add('disabled');
              } else if (offset < 0 && current <= min) {
                document.querySelector('[data-hero-arrow].left').classList.add('disabled');
              }
            },
          });
        }
      });
    });
  });

  document.querySelectorAll('[data-lightbox]').forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();

      if (el.dataset.videoSrc) {
        basicLightbox
          .create(`<video poster="${el.dataset.imageSrc}" autoplay loop muted playsinline src="${el.dataset.videoSrc}"></video>`)
          .show();
      } else {
        basicLightbox.create(`<img src="${el.dataset.imageSrc}">`).show();
      }
    });
  });

  document.querySelectorAll('[data-contract-bid]').forEach((button) => {
    // toggle disabled state if input is empty

    const input = document.querySelector(`*[data-contract-bid-value][data-day-of-year='${button.dataset.dayOfYear}']`);

    input.addEventListener('input', (e) => {
      if (!input.value) {
        button.classList.add('disabled');
        button.disabled = true;
      } else {
        button.classList.remove('disabled');
        button.disabled = false;
      }
    });

    button.addEventListener('click', (e) => {
      e.preventDefault();
      if (!provider) return console.error('[data-contract-bid] no provider');

      if (!input || parseFloat(input.value || '0') < 0.01) {
        document.querySelectorAll('[data-minimum-bid]').forEach((el) => {
          el.style.color = 'red';
          el.style.display = null;
        });
        return;
      } else {
        document.querySelectorAll('[data-minimum-bid]').forEach((el) => {
          el.style.color = null;
          el.style.display = 'none';
        });
      }

      Rails.ajax({
        type: 'post',
        url: `/bid/${button.dataset.dayOfYear}`,
        error: console.error,
        success: async (response) => {
          if (!response.ok) return console.warn('error', response);

          try {
            toggleBidLoader(button, true);

            const bid = await contract.methods
              .bid(response.token)
              .send({ from: getWalletAddress(provider), value: web3.utils.toWei(input.value, 'ether') });

            console.log(bid);

            // assume the transaction posted a few seconds after metamask shows it
            setTimeout(() => window.location.reload(), 10 * 1000);
          } catch (e) {
            console.warn('[bid]', e);
            toggleBidLoader(button, false);
          }
        },
      });
    });
  });

  const render_countdown = (el) => {
    if (el.seconds == null) el.seconds = parseInt(el.dataset.countdownSeconds || 0);

    if (el.seconds <= 0) {
      el.innerText = 'WAITING TO SETTLE';
      document
        .querySelectorAll(`[data-show-upon-settling][data-day-of-year="${el.dataset.dayOfYear}"]`)
        .forEach((el) => (el.style.display = null));
      document
        .querySelectorAll(`[data-hide-upon-settling][data-day-of-year="${el.dataset.dayOfYear}"]`)
        .forEach((el) => (el.style.display = 'none'));
      return;
    }

    if (el.seconds <= 10 * 60) {
      document.querySelectorAll('.warning.settling').forEach((el) => (el.style.display = null));
    }

    const levels = [
      [Math.floor((el.seconds % 31536000) / 86400), 'D'],
      [Math.floor(((el.seconds % 31536000) % 86400) / 3600), 'H'],
      [Math.floor((((el.seconds % 31536000) % 86400) % 3600) / 60), 'M'],
      [Math.floor((((el.seconds % 31536000) % 86400) % 3600) % 60), 'S'],
    ];

    const times = [];
    for (let i = 0, max = levels.length; i < max; i++) {
      if (levels[i][1] !== 'S' && levels[i][0] === 0) continue;
      times.push(`${levels[i][0]}${levels[i][1]}`);
    }

    el.innerHTML = el.seconds % 1 === 0 ? times.join('&nbsp;&nbsp;&nbsp;') : times.join('&nbsp;:&nbsp;');

    el.seconds -= 0.5;
  };

  document.querySelectorAll('[data-countdown-seconds]').forEach((el) => {
    render_countdown(el);
    setInterval(() => render_countdown(el), 500);
  });

  document.querySelectorAll('[data-insert-jazzicon]').forEach((el) => insertJazzicon(el));

  document.querySelectorAll('[data-insert-wallet-balance]').forEach(async (span) => {
    if (!provider) return;

    try {
      const balance = await web3.eth.getBalance(span.dataset.address);
      span.innerText = `${Math.round((parseFloat(web3.utils.fromWei(balance)) + Number.EPSILON) * 10000) / 10000} ETH`;
    } catch (e) {
      console.warn('[get balance]', e);
    }
  });

  document.querySelectorAll('[data-contract-batch-refund]').forEach(async (button) => {
    // get refund amounts
    const refundable = [];

    const bidTokens = button.dataset.bidTokens.split(',');

    bidTokens.forEach(async (bidToken, i) => {
      try {
        console.log('[data-contract-batch-refund] bidToken', bidToken);

        const bid = await contract.methods.getBidToken(bidToken).call();
        console.log('[data-contract-batch-refund] bidToken', bidToken, bid);

        if (bid['0'] <= 0) {
          console.log(`[data-contract-batch-refund] bidToken ${bidToken} already refunded, skipping`);
        } else {
          refundable.push(bidToken);
        }
      } catch (e) {
        console.warn('[data-contract-batch-refund] error getting bid', e);
      }

      if (i == bidTokens.length - 1) {
        console.log('[data-contract-batch-refund] refundable', refundable);
        document.querySelector('#batch-refunds-count').innerText = refundable.length;

        if (refundable.length == 0) {
          document.querySelector('#batch-refunds-loading').innerText = 'No days found to refund.';
          button.style.display = 'none';
        } else {
          document.querySelector('#batch-refunds-loading').style.display = 'none';
          button.disabled = false;
          button.classList.remove('disabled');
          button.classList.add('stroked');

          button.addEventListener('click', async (e) => {
            e.preventDefault();

            if (!provider) return console.error('[data-contract-batch-refund] no provider');

            try {
              const from = getWalletAddress();
              const refund = await contract.methods.refund(refundable).send({ from });
              console.log(refund);
            } catch (e) {
              console.warn('[data-contract-batch-refund]', e);
            }
          });
        }
      }
    });
  });

  document.querySelectorAll('[data-contract-refund]').forEach(async (button) => {
    // get refund amount
    try {
      const bid = await contract.methods.getBidToken(button.dataset.bidToken).call();
      console.log('[data-contract-refund] bid', bid);

      if (bid['0'] <= 0) {
        button.disabled = true;
        button.innerText = 'REFUNDED';
        button.className = 'button disabled';
      }
    } catch (e) {
      console.warn('[data-contract-refund] error getting bid', e);
    }

    button.addEventListener('click', async (e) => {
      e.preventDefault();

      if (!provider) return console.error('[data-contract-refund] no provider');
      if (!(button.dataset.bidToken && button.dataset.nonce && button.dataset.signature)) return console.error('Invalid refund params');

      try {
        const from = getWalletAddress();

        const refund = await contract.methods
          .signedRefund(button.dataset.bidToken, button.dataset.nonce, button.dataset.signature)
          .send({ from });
        console.log(refund);
      } catch (e) {
        console.warn('[refund]', e);
      }
    });
  });

  document.querySelectorAll('[data-quick-bid]').forEach(async (button) => {
    button.addEventListener('click', async (e) => {
      e.preventDefault();

      const input = document.querySelector('[data-contract-bid-value]');
      const bid_button = document.querySelector('[data-contract-bid]');

      if (!input || !bid_button) return;

      input.value = button.dataset.quickBid;
      bid_button.classList.remove('disabled');
      bid_button.disabled = false;
    });
  });

  let observer = new IntersectionObserver(
    (changes) => {
      changes.forEach((change) => {
        if (change.isIntersecting) {
          console.log('lazyloading image', change);
          change.target.src = change.target.dataset.lazyload;
          observer.unobserve(change.target);
        }
      });
    },
    { threshold: 0, rootMargin: '100px' },
  );

  [...document.querySelectorAll('img[data-lazyload]')].forEach((img) => {
    observer.observe(img);
  });
});
